
    import axios from 'axios';
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class MiscCovid19 extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private readonly errors: any[]     = [];

        private readonly countryDeathsGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'United States of America',
                    x:      [],
                    y:      [],
                    line:   {color: "#0000FF", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Brazil',
                    x:      [],
                    y:      [],
                    line:   {color: "#009C3B", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'United Kingdom',
                    x:      [],
                    y:      [100, 120, 130],
                    line:   {color: "#C8102E", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'India',
                    x:      [],
                    y:      [],
                    line:   {color: "#808080", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'France',
                    x:      [],
                    y:      [],
                    line:   {color: "#0050A4", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Spain',
                    x:      [],
                    y:      [],
                    line:   {color: "#FF8000", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Russian Federation',
                    x:      [],
                    y:      [],
                    line:   {color: "#FF00FF", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Germany',
                    x:      [],
                    y:      [],
                    line:   {color: "#FFFF00", width: 4}
                },
            ],
            // tslint:enable
        };

        private readonly countryTotalDeathsGraph = {
            // tslint:disable
            traces: [{
                type:           'pie',
                rotation:       0,
                values:         [9, 8, 7, 6, 5, 4, 3, 2],
                marker: {
                    colors: ['#0000FF', '#009C3B','#C8102E', '#808080', '#0050A4', '#FF8000', '#FF00FF', '#FFFF00'],
                },
                labels:         ['United States of America', 'Brazil', 'United Kingdom', 'India', 'France', 'Spain', 'Russian Federation', 'Germany'],
                textinfo:       "label+value",
                hoverinfo:      "label+value",
                textposition:   "outside",
                automargin:     false,
                showlegend:     false
            }],
            // tslint:enable
        };

        private readonly deathRatioGraph = {
            // tslint:disable
            traces: [
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'United States of America',
                    x:      [],
                    y:      [],
                    line:   {color: "#0000FF", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Brazil',
                    x:      [],
                    y:      [],
                    line:   {color: "#009C3B", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'United Kingdom',
                    x:      [],
                    y:      [100, 120, 130],
                    line:   {color: "#C8102E", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'India',
                    x:      [],
                    y:      [],
                    line:   {color: "#808080", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'France',
                    x:      [],
                    y:      [],
                    line:   {color: "#0050A4", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Spain',
                    x:      [],
                    y:      [],
                    line:   {color: "#FF8000", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Russian Federation',
                    x:      [],
                    y:      [],
                    line:   {color: "#FF00FF", width: 4}
                },
                {
                    type:   "scatter",
                    mode:   "lines",
                    name:   'Germany',
                    x:      [],
                    y:      [],
                    line:   {color: "#FFFF00", width: 4}
                },
            ],
            // tslint:enable
        };

        // Methods -----------------------------------------------------------------------------------------------------
        private get createCountryLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "", 64, 16);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 0.0, y: 1.0, xanchor: 'left', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createTotalDeathsLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "", 64, 32);
            // tslint:enable
        }

        private get createDeathsRatioLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "", 64, 16);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 0.0, y: 1.0, xanchor: 'left', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private data() {
            return {
                covid19ImagePathAndName:
                    require("@/assets/images/covid_19.png"),

                covid19IconPathAndName:
                    require("@/assets/images/icon_covid_19.png"),

                faceMaskImagePathAndName:
                    require("@/assets/images/face_mask.png"),
            };
        }

        private created() {
            this.requestCovid19DailyDeathsJson();
            this.requestCovid19TotalDeathsJson();
        }

        private requestCovid19DailyDeathsJson(): void {
            /* https://api.covid19api.com/dayone/country/united-kingdom/status/confirmed*/
            const covid19apiUrl         = "https://api.covid19api.com/dayone";
            const america               = "/country/united-states";
            const parameter             = "/status/deaths";
            let index                   = 0;
            const americaPopulation     = 328200000;
            this.downloadStatistics(covid19apiUrl + america + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, americaPopulation, index++);

            const brazil                = "/country/brazil";
            const brazilPopulation            = 210000000;
            this.downloadStatistics(covid19apiUrl + brazil + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, brazilPopulation, index++);

            const uk                    = "/country/united-kingdom";
            const ukPopulation          = 66650000;
            this.downloadStatistics(covid19apiUrl + uk + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, ukPopulation, index++);

            const india                 = "/country/india";
            const indiaPopulation       = 136600000;
            this.downloadStatistics(covid19apiUrl + india + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, indiaPopulation, index++);

            const france                = "/country/france";
            const francePopulation      = 66990000;
            this.downloadStatistics(covid19apiUrl + france + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, francePopulation, index++);

            const spain                 = "/country/spain";
            const spainPopulation       = 46940000;
            this.downloadStatistics(covid19apiUrl + spain + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, spainPopulation, index++);

            const russia                = "/country/russia";
            const russiaPopulation      = 14670000;
            this.downloadStatistics(covid19apiUrl + russia + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, russiaPopulation, index++);

            const germany               = "/country/germany";
            const germanyPopulation     = 83020000;
            this.downloadStatistics(covid19apiUrl + germany + parameter,
                this.countryDeathsGraph, this.deathRatioGraph, germanyPopulation, index++);
        }

        private downloadStatistics(url: string, graph: any, ratioGraph: any, population: number, traceIndex: number): void {
            axios.get(url).then((response: any) => {
                this.parseToGraph(graph, ratioGraph, population, response.data, traceIndex);
            }).catch((e) => { this.errors.push(e); });
        }

        private parseToGraph(graph: any, ratioGraph: any, population: number, data: [any], traceIndex: number): void {
            graph.traces[traceIndex].x      = [];
            graph.traces[traceIndex].y      = [];

            ratioGraph.traces[traceIndex].x = [];
            ratioGraph.traces[traceIndex].y = [];

            let lastDate                    = data[0].Date;
            let lastDayOfTheMonth           = 0;
            let lastDayTotalDeaths          = data[0].Cases;
            data.forEach((item: any) => {
                const deaths                = item.Cases;
                const date                  = item.Date;
                const province              = item.Province;
                if (province === "") {  // Empty province seems to equal the whole of the country
                    const dateObject            = new Date(date);
                    const dayOfTheMonth         = dateObject.getDate();
                    const isNewDayOfTheMonth    = dayOfTheMonth !== lastDayOfTheMonth;
                    if (true || isNewDayOfTheMonth) {
                        /*console.log(dayOfTheMonth + " Storing: " + lastDayTotalDeaths + ", deaths: " + deaths);*/
                        this.pushDailyData(graph, ratioGraph, population, traceIndex, lastDate, lastDayTotalDeaths);

                        lastDate            = date;
                        lastDayOfTheMonth   = dayOfTheMonth;
                        lastDayTotalDeaths  = deaths;
                    } else {
                        lastDayTotalDeaths += deaths;
                        /*console.log("   " + " Adding: " + deaths);*/
                    }
                }
            });
        }

        private pushDailyData(graph: any,
                              ratioGraph: any,
                              population: number,
                              traceIndex: number,
                              date: string,
                              deaths: number): void {
            graph.traces[traceIndex].x.push(date);
            graph.traces[traceIndex].y.push(deaths);

            ratioGraph.traces[traceIndex].x.push(date);
            ratioGraph.traces[traceIndex].y.push(deaths / population);
        }

        private requestCovid19TotalDeathsJson(): void {
            /* https://api.covid19api.com/dayone/country/united-kingdom/status/confirmed*/
            const covid19apiUrl         = "https://api.covid19api.com/summary";

            axios.get(covid19apiUrl).then((response: any) => {
                this.parseToPieChart(this.countryTotalDeathsGraph, response.data.Countries);
            }).catch((e) => { this.errors.push(e); });
        }

        private parseToPieChart(graph: any, data: [any]): void {
            graph.traces[0].values  = [];

            const america = data.filter((object) => (object.Country === "United States of America"));
            graph.traces[0].values.push(america[0].TotalDeaths);

            const brazil = data.filter((object) => (object.Country === "Brazil"));
            graph.traces[0].values.push(brazil[0].TotalDeaths);

            const uk = data.filter((object) => (object.Country === "United Kingdom"));
            graph.traces[0].values.push(uk[0].TotalDeaths);

            const india = data.filter((object) => (object.Country === "India"));
            graph.traces[0].values.push(india[0].TotalDeaths);

            const france = data.filter((object) => (object.Country === "France"));
            graph.traces[0].values.push(france[0].TotalDeaths);

            const spain = data.filter((object) => (object.Country === "Spain"));
            graph.traces[0].values.push(spain[0].TotalDeaths);

            const russia = data.filter((object) => (object.Country === "Russian Federation"));
            graph.traces[0].values.push(russia[0].TotalDeaths);

            const germany = data.filter((object) => (object.Country === "Germany"));
            graph.traces[0].values.push(germany[0].TotalDeaths);

            console.log(brazil[0].TotalDeaths);
        }
    }
